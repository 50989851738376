<template>
  <div class="main-container">
    <div class="section-header">
      <v-row>
        <v-col cols="12" md="3" lg="3">
          <label class="lfont">{{ $t('meeting.month_year') }}</label>
          <DatePicker class="date-time" style="width: 100%" type="month" valueType="format" slot="activator"
            :append-to-body="true" name="founding_date" v-model="month_year" @change="fetchMeetingList"></DatePicker>
        </v-col>
      </v-row>
    </div>
    <div class="section-content lfont">
      <v-row>
        <v-col cols="12" md="4" lg="4" v-for="(item, i) in meetingList" :key="i">
          <v-card :loading="loading" class="mx-auto my-12" max-width="374">
            <v-card-title>{{ item.title }}</v-card-title>

            <v-card-text>
              <v-row align="center" class="mx-0">
                <div class="grey--text ms-4">
                  <i class="fa fa-bullhorn"></i> {{ item.creator }},
                  {{ item.created_at }}
                </div>
              </v-row>
              <div class="my-4 text-subtitle-1"></div>
              <div>
                {{ item.detail }}
              </div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              <p class="text-primary" v-for="meet_time in item.meeting_time" :key="meet_time.id">
                <i class="fal fa-calendar"></i> {{ meet_time.meet_date }}
                {{ meet_time.start }} - {{ meet_time.end }}
              </p>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
              <div class="font-weight-bold ml-8 mb-2">Participants</div>

              <v-timeline align-top dense>
                <v-timeline-item v-for="employee in item.emloyees" :key="employee.id" color="deep-purple lighten-1" small>
                  <div>
                    <div class="font-weight-normal">
                      <strong>{{ employee.name }} {{ employee.surname }}</strong>
                    </div>
                    <div>
                      <v-chip v-for="emp_meet_time in employee.employee_meeting_time" :key="emp_meet_time.id" :style="{
                        backgroundColor: bgEmployeeMeetTime(
                          emp_meet_time.status
                        ),
                        color: clEmployeeMeetTime(emp_meet_time.status),
                        border: '1px solid',
                      }">{{ emp_meet_time.meet_date }}
                      </v-chip>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>

            <v-card-actions>
              <!--  <v-btn color="deep-purple lighten-2" text @click="reserve">
                Reserve
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  components: { DatePicker },
  data: () => ({
    month_year: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    loading: false,
    selection: 1,
    meetingList: [],
  }),
  methods: {
    bgEmployeeMeetTime(status) {
      if (status == "pendding") {
        return "white !important";
      } else if (status == "join") {
        return "green !important";
      }
      return "red !important";
    },
    clEmployeeMeetTime(status) {
      if (status != "pendding") {
        return "white !important";
      }
    },
    fetchMeetingList() {
      let month = moment(this.month_year).format("MM");
      let year = moment(this.month_year).format("YYYY");
      this.$axios
        .get(`company/meeting/list`, { params: { month: month, year: year } })
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.meetingList = res.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchMeetingList();
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  .section-header {
    padding: 20px;
    padding-bottom: 0px;
  }

  .section-content {
    padding: 20px;
  }
}
</style>
