<template>
  <div class="form-layout">
    <table class="table table-detail" v-if="LeaveDetail.leave_time.length > 0">
      <tr>
        <td rowspan="2" class="text-center">
          {{ $t("eLeave.showLeaveDetail.date") }}
        </td>
        <td colspan="2" class="text-center">ເວລາ</td>
      </tr>
      <tr>
        <td class="text-center">{{ $t("eLeave.showLeaveDetail.start") }}</td>
        <td class="text-center">{{ $t("eLeave.showLeaveDetail.end") }}</td>
      </tr>
      <tbody>
        <tr v-for="(item_detail, k) in LeaveDetail.leave_time" :key="k">
          <th class="text-center text-danger">
            {{ moment(item_detail.date).format("DD/MM/YYYY") }}
          </th>
          <th class="text-center text-danger">
            {{ item_detail.start_time }}
          </th>
          <th class="text-center text-danger">
            {{ item_detail.end_time }}
          </th>
        </tr>
      </tbody>
    </table>

    <table class="table table-detail" v-else>
      <tr>
        <td class="text-center text-primary">
          {{ $t("eLeave.showLeaveDetail.start") }}
        </td>
        <td class="text-center text-primary">
          {{ $t("eLeave.showLeaveDetail.end") }}
        </td>
      </tr>
      <tr>
        <th class="text-danger text-center">{{ LeaveDetail.start_date }}</th>
        <th class="text-danger text-center">{{ LeaveDetail.end_date }}</th>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    LeaveDetail: {}
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.form-layout {
  padding: 10px;
  padding-bottom: 50px;
}
.table-detail {
  margin-top: 20px;
}
.table-detail tr td,
.table-detail tr th {
  border: 1px solid rgb(183, 183, 183) !important;
  font-family: $font-family;
}
.table-detail tr td {
  font-weight: 700;
  font-size: 16px;
}
.table-view td {
  border-right: 1px solid rgb(172, 172, 172);
}
</style>
