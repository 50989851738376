<template>
  <div class="main-container lfont">
    <div class="section-header"></div>
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <div class="form-input">
          <label for="">Title</label>
          <v-text-field
            placeholder=""
            outlined
            dense
            v-model="title"
          ></v-text-field>
          <label for="">Attach file</label>
          <div class="choose-image">
            <div class="show-image">
              <i class="fas fa-cloud-upload-alt"></i>
              <input
                @change="PreviewImage"
                type="file"
                class="input-file-image"
                accept=".pdf,image/*"
              />
              <img v-if="showImage" :src="showImage" alt="" />
              <embed
                class="embed-pdf"
                v-if="showPDF"
                :src="showPDF"
                type="application/pdf"
                accept="application/pdf"
              />
            </div>
          </div>
          <v-btn
            icon
            color="pink"
            v-if="attach_file"
            @click="
              attach_file = '';
              showImage = '';
            "
          >
            <v-icon>mdi-trash-can</v-icon> </v-btn
          ><br />

          <label for="">Detail</label>
          <quill-editor
            class="editor"
            ref="myTextEditor"
            :value="content"
            :options="editorOption"
            @change="onEditorChange"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            style="min-height: 300px"
          /><br />

          <div v-if="edit_id">
            <v-btn color="warning" class="btn-create lfont" @click="update">
              {{ $t("activity.anouncement.update") }}
            </v-btn>
            <v-btn color="red" class="btn-create lfont" @click="cancelUpdate">
              {{ $t("activity.anouncement.cancel") }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn color="info" class="btn-create lfont" @click="saveData">
              {{ $t("activity.anouncement.save") }}
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <div class="section-form-content">
          <div v-if="announcementList.length > 0">
            <div class="card" v-for="(item, i) in announcementList" :key="i">
              <div class="anou-vector">
                <img src="@/assets/vector.png" alt="" />
              </div>
              <div class="anou-content">
                <div class="title">
                  <h5 class="lfont">{{ item.title }}</h5>
                  <span class="create">{{ item.create }}</span>
                </div>
                <p v-html="htmlFromDb(item.full_detail)"></p>
              </div>
              <div class="action">
                <v-btn
                  class="mx-2 btn"
                  fab
                  dark
                  small
                  color="warning"
                  @click="onEdit(item)"
                >
                  <v-icon dark> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  class="mx-2 btn"
                  fab
                  dark
                  small
                  color="red"
                  @click="onDeleted(item.id)"
                >
                  <v-icon dark> mdi-trash-can </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="paginate" v-if="pagination.total_pages > 1">
              <Pagination
                :pagination="pagination"
                :offset="offset"
                @paginate="fetchData"
              >
              </Pagination>
            </div>
          </div>
          <defaultTableNoResult v-else />
        </div>
      </v-col>
    </v-row>
    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteBranch
          :delete_id="delete_id"
          @close="close"
          @success="fetchData()"
        />
      </template>
    </ModalDelete>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import dedent from "dedent";
import hljs from "highlight.js";
import debounce from "lodash/debounce";
import { quillEditor } from "vue-quill-editor";
// highlight.js style
import "highlight.js/styles/tomorrow.css";
// import theme style
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import DeleteBranch from "@/components/Announcement/delete";
export default {
  components: {
    defaultTableNoResult,
    quillEditor,
    Loading,
    Pagination,
    DeleteBranch,
  },
  data() {
    return {
      showImage: "",
      showPDF: "",
      imageFile: "",
      attach_file: "",

      delete_id: "",
      edit_id: false,
      title: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            //["clean"],
            ["link", "image", "video"],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
          //imageResize: true,
        },
      },
      content: dedent``,
      isLoading: false,
      per_page: 3,
      offset: 10,
      pagination: {},
      announcementList: [],
    };
  },
  methods: {
    PreviewImage: function (event) {
      const input = event.target;

      const file = input.files[0];
      if (file == undefined) return;
      this.isLoading = true;
      const fileType = file["type"];
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be jpeg,png"
        );
      } else if (input.files && input.files[0]) {
        this.imageFile = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          if (this.imageFile.type == "application/pdf") {
            this.showPDF = e.target.result;
          } else {
            this.showImage = e.target.result;
          }
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadImage(this.imageFile);
      }
    },
    UploadImage(imageFile) {
      let formData = new FormData();
      formData.append("imageFile", imageFile);
      this.$axios
        .post(`upload-file`, formData)
        .then((res) => {
          console.log(res);
          this.isLoading = false;
          if (res.status === 200) {
            this.attach_file = res.data.filename;
            this.isLoading = false;
          } else if (res.status === 413) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              "ຂະໜາດໄຟຣໃຫຍ່ເກີນໄປ"
            );
          }
        })
        .catch((e) => {
          this.$notification.ShowErrors(
            "top-right",
            "danger",
            3000,
            "ຂະໜາດໄຟຣໃຫຍ່ເກີນໄປ"
          );
          this.isLoading = false;
        });
    },
    onDeleted(id) {
      this.delete_id = id;
      this.$store.commit("modalDelete_State", true);
    },
    update() {
      this.isLoading = true;
      let items = {
        title: this.title,
        detail: this.content,
        attach_file: this.attach_file,
      };
      this.$axios
        .put(`company/anouncement/update/${this.edit_id}`, items)
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.showImage = "";
            this.showPDF = "";
            this.imageFile = "";
            this.attach_file = "";

            this.edit_id = false;
            this.content = dedent``;
            this.title = "";
            this.fetchData();
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    cancelUpdate() {
      this.edit_id = false;
      this.title = "";
      this.content = dedent``;
      this.showImage = "";
      this.showPDF = "";
      this.attach_file = "";
    },
    onEdit(item) {
      this.edit_id = item.id;
      this.content = dedent`${item.detail}`;
      this.title = item.title;
      this.attach_file = item.attach_file;
      if (item.file_type == "pdf") {
        this.showPDF = item.attach_file;
      } else {
        this.showImage = item.attach_file;
      }
    },
    htmlFromDb(data) {
      return data;
    },
    saveData() {
      this.isLoading = true;
      let items = {
        title: this.title,
        detail: this.content,
        attach_file: this.attach_file,
      };
      this.$axios
        .post(`company/anouncement/add`, items)
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.showImage = "";
            this.showPDF = "";
            this.imageFile = "";
            this.attach_file = "";
            this.content = dedent``;
            this.title = "";
            this.title = "";
            this.fetchData();
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    fetchData() {
      this.isLoading = true;
      this.$axios
        .get(`anouncement/list`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.announcementList = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.announcementList.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onEditorChange: debounce(function (value) {
      this.content = value.html;
    }, 466),
    onEditorBlur(editor) {
      //console.log("editor blur!", editor);
    },
    onEditorFocus(editor) {
      //console.log("editor focus!", editor);
    },
    onEditorReady(editor) {
      //console.log("editor ready!", editor);
    },
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.anou-vector {
  width: 20%;
}
.main-container {
  padding: 10px;
  background: rgb(239, 239, 240);
  .paginate {
    background: white;
    padding: 10px;
    border-radius: 5px;
  }
  .section-header {
    margin-bottom: 10px;
  }
  .form-input {
    background: white;
    padding: 10px;
    .btn-create {
      color: white !important;
      margin: 10px;
    }
  }
  .card {
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    border-left: 10px solid blue;
    .anou-content {
      margin-left: 20px;
      width: 75%;
      .title {
        h5 {
          margin-bottom: 2px;
        }
        .create {
          color: rgb(17, 0, 255);
          font-size: 14px;
        }
      }
    }
    .action {
      .btn {
        font-size: 12px !important;
        margin: 5px 0px;
      }
    }
  }
}
</style>