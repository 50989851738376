<template>
    <div class="main-report">
        <div class="section-header">
            <h2>
                <span><i class="far fa-list-alt"></i></span>{{ $t("follow_not_scan.follow") }}
            </h2>
        </div>
        <v-sheet class="card-select-date" width="100%">
            <v-row dense>
                <v-col cols="12" md="2" lg="2">
                    <label class="label-input">{{ $t("follow_not_scan.start_date") }}</label>
                    <DatePicker class="date-time" style="width: 100%" type="date" v-model="start_date" valueType="format"
                        slot="activator" :append-to-body="true" name="founding_date" @change="searchFilterItem()">
                    </DatePicker>
                </v-col>
                <v-col cols="12" md="2" lg="2">
                    <label class="label-input">{{ $t("follow_not_scan.end_date") }}</label>
                    <DatePicker class="date-time" style="width: 100%" type="date" v-model="end_date" valueType="format"
                        slot="activator" :append-to-body="true" name="founding_date" @change="searchFilterItem()">
                    </DatePicker>
                </v-col>

                <v-col cols="12" md="3" lg="3">
                    <label class="label-input">{{
                        $t("follow_not_scan.qty_day")
                    }}</label>
                    <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="qty_day"
                        prepend-inner-icon="mdi-magnify" type="number" @keypress.enter="searchFilterItem">
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3" lg="3">
                    <label class="label-input">{{
                        $t("follow_not_scan.search_title")
                    }}</label>
                    <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="searchItem"
                        prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')"
                        @keypress.enter="searchFilterItem">
                    </v-text-field>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet class="mt-4 pa-4" width="100%" height="auto" color="white">
            <table class="table table-view" v-if="listEmployeeCheckin.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>
                            {{ $t("follow_not_scan.table.qty_not_scan") }}
                        </th>
                        <th class="text-left">
                            {{ $t("follow_not_scan.table.employee") }}
                        </th>
                        <th class="text-center">
                            {{ $t("follow_not_scan.table.absent_date") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in listEmployeeCheckin" :key="idx">
                        <td>{{ idx + 1 }}</td>
                        <td class="text-danger">{{ item.qty_day_absent }}</td>
                        <td>
                            <span class="mr-2" style="font-size: 14px !important;">{{ item.emp_number }}</span>
                            <span class="text-primary" style="font-size: 18px !important;"><b>{{ item.name }} {{
                                item.surname }}</b></span>
                            <p>{{ $t("follow_not_scan.table.dept") }}: <span class="text-primary">{{ item.dept }}</span>, {{
                                $t("follow_not_scan.table.branch") }}: <span class="text-primary">{{ item.dept }}</span></p>
                        </td>
                        <td>
                            <v-chip class="ma-1 " v-for="(item, i) in item.absent_date"
                                style="background-color: rgb(239, 17, 17) !important; color: rgb(255, 255, 255);">
                                <span class="date-absent m-1 p-1">{{ item.week_day }}</span> {{ item.date }}
                            </v-chip>
                        </td>
                    </tr>
                </tbody>
            </table>

            <defaultTableNoResult v-else />

            <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
                @paginate="fetchEmployeeCheckin">
            </Pagination>
        </v-sheet>
        <loading v-if="isLoading" />
    </div>
</template>
  
<script>
import moment from "moment"; // import xlsx;
import DatePicker from "vue2-datepicker";
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import "vue2-datepicker/index.css";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
    components: {
        Loading,
        Pagination,
        defaultTableNoResult,
    },
    data() {
        return {
            offset: 10,
            pagination: {},
            per_page: 10,
            dialog: false,
            searchItem: "",
            isLoading: false,
            listEmployeeCheckin: [],

            start_date: "",
            end_date: new Date().toISOString().slice(0, 10),
            branchId: "",
            departmentId: "",
            qty_day: 10
        };
    },

    methods: {
        searchFilterItem() {
            this.pagination.current_page = 1;
            this.fetchEmployeeCheckin();
        },

        fetchEmployeeCheckin() {
            this.isLoading = true;
            this.$axios
                .get(`company/report/employee/not/clockin`, {
                    params: {
                        filter: this.searchItem,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        qty_day: this.qty_day,
                        branchId: this.branchId,
                        departmentId: this.departmentId,
                        page: this.pagination.current_page,
                        per_page: this.per_page
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        this.isLoading = false;
                        this.listEmployeeCheckin = res.data.data.data;
                        this.pagination = res.data.data.pagination;
                        if (!this.listEmployeeCheckin.length > 0) {
                            this.pagination.current_page = this.pagination.current_page - 1;
                        }
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
    },
    created() {
        let date = new Date();
        let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.start_date = moment(firstDate).format("YYYY-MM-DD");
        this.fetchEmployeeCheckin();
    },
};
</script>
  
<style scoped lang="scss">
.date-absent {
    background-color: rgb(255, 255, 255) !important;
    color: #020202;
    border-radius: 50%;
}

.margin-top-6 {
    margin-top: 6px;
}

.main-report {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(223, 230, 233, 0.3);
    font-family: $font-family;

    h2 {
        font-size: 20px;
    }

    .card-select-date {
        display: flex;
        align-items: center;
        padding: 15px;
    }

    .section-header {
        padding: 13px;
        background-color: rgb(255, 255, 255) !important;
    }
}

.status {
    font-size: 12px;
    letter-spacing: 1px;
    padding: 2px 8px;
    width: 60px;
    height: 20px;
    text-transform: capitalize;
    font-family: $font-family;
    border-radius: 60px;
}

.pending {
    background-color: rgba(128, 142, 155, 1);
    color: #ffffff;
}

.confirm {
    background-color: rgba(46, 204, 113, 1) !important;
    color: #ffffff;
}

.updated {
    background-color: rgba(255, 221, 89, 1) !important;
    color: #ffffff;
}

.rejected {
    background-color: rgba(192, 57, 43, 1) !important;
    color: #ffffff;
}

.approve {
    background-color: rgba(38, 222, 129, 1);
    color: #ffffff;
}

.success {
    background-color: rgba(30, 144, 255, 1) !important;
    color: #ffffff;
}
</style>
  